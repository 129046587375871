/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Audio player MMI component
 *
 * @file AudioModal.jsx
 * @author JP
 * @since 1.0.0
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-extraneous-dependencies
import { AudioPlayerComponent } from '@aquila/media';
import { Icon } from '@greenville/mui-theme';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';

class AudioModal extends Component {
  // render the component if the url property of audio object has been changed
  shouldComponentUpdate(nextProps) {
    if (this.props.isAudioModalShown === nextProps.isAudioModalShown) {
      if (this.props.audioObject && (this.props.audioObject.url === nextProps.audioObject.url)) {
        return false;
      }

      return true;
    }

    return true;
  }

  getKey = () => new Date().getUTCMilliseconds();

  render() {
    const {
      audioObject,
      isAudioModalShown,
      onClose
    } = this.props;

    if (isAudioModalShown) {
      const data = audioObject;

      return (
        <Snackbar
          open={isAudioModalShown}
          className="popoutAudioContainer audioContainer"
          key={this.getKey()}
        >
          <>
            <IconButton aria-label="Remove" color="inherit" onClick={onClose}>
              <Icon name="Remove" fontSize="small" color="inherit" />
            </IconButton>
            { /* eslint-disable-next-line react/jsx-props-no-spreading */ }
            <AudioPlayerComponent {...data} useCredentials={false} />
          </>
        </Snackbar>

      );
    }

    return null;
  }
}

AudioModal.propTypes = {
  audioObject: PropTypes.object,
  isAudioModalShown: PropTypes.bool,
  onClose: PropTypes.func
};

AudioModal.defaultProps = {
  audioObject: {},
  isAudioModalShown: false,
  onClose: () => {}
};

export default AudioModal;
