/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Details Form utility
 *
 * @file DetailsFormUtils.jsx
 * @author Kranthikiran Reddy Mattimadugu
 * @since 2.0.0
 */

import { BOOK_DATA_ARRAY } from '../common/constants';

class DetailsFormUtils {
    constructor() {
        this.initialize();
    }

    /**
    * Initialize
    * 
    */
    initialize = () => {
        this.booksData = JSON.parse(localStorage.getItem(BOOK_DATA_ARRAY));
    };

    /**
    * Gets the Last submitted book details
    * 
    */
    getLatestStateData = () => {
        let booksData = this.booksData;
        let latestStateData = Object.values(booksData[booksData.length - 1])[0];

        return latestStateData;
    };

    /**
    * Gets the all submitted book details
    * From Local Storage
    * 
    */
    getBooksFromLocalStorage = (BOOK_DATA_ARRAY) => {
        return localStorage.getItem(BOOK_DATA_ARRAY);
    };

    /**
    * Sets the all submitted book details
    * Into Local Storage
    * 
    */
    setBooksIntoLocalStorage = (BOOK_DATA_ARRAY,booksData) => {
        return localStorage.setItem(BOOK_DATA_ARRAY, JSON.stringify(booksData));
    };

    /**
    * Removes all submitted book details
    * From Local Storage
    * 
    */
    removeBooksFromLocalStorage = (BOOK_DATA_ARRAY) => {
        return localStorage.removeItem(BOOK_DATA_ARRAY);
    };
};

export default DetailsFormUtils;
