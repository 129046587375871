/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Sample Dialog component
 *
 * @file DialogSample.jsx
 * @author Sam George
 * @since 2.0.0
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class DialogSample extends PureComponent {
  render() {
    const {
      onClose,
      open
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          Sample Notification
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Duis quis felis vehicula, malesuada nulla a, lobortis elit.
            Nunc sem est, egestas ac luctus ut, convallis vitae leo.
            Integer eget quam in ex tempus faucibus in vel nunc. Donec quam nibh, finibus in placerat vitae,
            placerat efficitur justo. Aliquam in placerat ipsum. Praesent ac consectetur dui.
            Phasellus faucibus vehicula blandit. Aliquam aliquet nunc at ex ultricies, in ultrices eros consectetur.
            Nulla et hendrerit dolor, sit amet tempus nibh. Aenean vulputate in.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogSample.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

DialogSample.defaultProps = {
  onClose: () => {},
  open: false
};

export default DialogSample;
