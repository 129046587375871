import citeData from '../asset/data/CITEData';

const appConfig = {
  authCdnTokenName: 'gcs-cdn-token',
  authCdnTokenValue: 'exp=1570712051~acl=/eps/sanvan/api/item/*/*/file/**~hmac=3c7300aed653eb7e9717e16e33b874e17f043288624b6d15436591af2fb25c57',
  initial: {
    allowNotesCopy: false,
    allowTocEdit: false,
    applicationId: '',
    authApigee: false,
    basePath: '',
    bookId: '',
    clientApp: '',
    contentType: '',
    courseId: '',
    env: '',
    hawkId: '',
    hawkKey: '',
    indexId: '',
    enableQuickView: false,
    enableUserLastLocation: false,
    isDoublePage: false,
    isSpdfContent: false,
    isStudent: false,
    locale: '',
    legacyBookId: '',
    mathmlOn: false,
    masterTheme:'standard',
    pageId: '',
    showAudio: false,
    showBackNav: false,
    showBookmark: false,
    showCopy: false,
    showDrawer: false,
    showDropMenu: false,
    showFoc: false,
    showFontSlider: true,
    showHeader: false,
    showHighlights: false,
    showLastVisitedPage: false,
    showNavigation: true,
    showPageLayout: false,
    showPref: false,
    showPrintPageNav: true,
    showResource: false,
    showSearch: false,
    showShareNote: false,
    showSocialComponent: false,
    showStudy: false,
    showStudyPanelChannelsWidget: false,
    showFlashcardViewInContent: false,
    showToc: true,
    theme: 'White',
    tocData: '',
    token: '',
    type: '',
    tenantId: '',
    tenantKey: '',
    userId: '',
    userRole: '',
    versionUrn: ''
  },
  updated: {
    allowNotesCopy: true,
    allowTocEdit: true,
    applicationId: 'ise',
    authApigee: false,
    basePath: 'https://etext-ise-stg.pearson.com/eps/sanvan/api/item/e0e48c8e-9bc9-4e0e-b602-431278a65bb5/1/file',
    bookId: '050be2bd-acd0-4070-ac95-dfe141f09236',
    clientApp: 'ISE',
    contentType: 'CITE',
    courseId: '6033af190d94a7001af05191',
    env: 'stg',
    frToken: '',
    hawkId: '',
    hawkKey: '',
    indexId: '005a6ac7-a290-4fef-94dc-0abc2ec5edba',
    enableQuickView: false,
    enableUserLastLocation: false,
    isDoublePage: false,
    isSpdfContent: false,
    isStudent: true,
    locale: 'en',
    legacyBookId: '65038',
    mathmlOn: true,
    masterTheme:'plus',
    pageId: '',
    showAudio: true,
    showBackNav: true,
    showBookmark: true,
    showCopy: false,
    showDrawer: true,
    showDropMenu: true,
    showFoc: false,
    showFontSlider: true,
    showHeader: true,
    showHighlights: true,
    showLastVisitedPage: true,
    showNavigation: true,
    showPageLayout: false,
    showPref: true,
    showPrintPageNav: true,
    showResource: false,
    showSearch: true,
    showShareNote: false,
    showSocialComponent: false,
    showStudy: true,
    showStudyPanelChannelsWidget: false,
    showFlashcardViewInContent: false,
    showToc: true,
    theme: 'White',
    tocData: JSON.stringify(citeData.asset),
    token: 'eyJraWQiOiJrNzIxNy4xNTYzOTc0NzMwIiwiYWxnIjoiUlM1MTIifQ.eyJzdWIiOiJmZmZmZmZmZjU1MDk5NzM2ZTRiMGMyZGJkNDEyOTM4YyIsImhjYyI6IlVTIiwidHlwZSI6ImF0IiwiZXhwIjoxNTcxMjI5NzY0LCJpYXQiOjE1NzEyMjc5NjMsImNsaWVudF9pZCI6IktDcGpuYnFBTkIwUmtJOGFvaHo1dVJCTUFHZU41RkdBIiwic2Vzc2lkIjoiYzdmMTNhMzgtOGQxMy00M2U1LWEyMjUtMWFjMDgxNDIxZDQwIn0.p6dwSzVP_fR6u1p-fejzqdDIKYNqmLanyxGWEavZh5wEMswJ6r0cCxY36Iy8J8E5DOU9JWYexU7zsc6aN-RHMv86jXmbBiKCJPtbNDpqgMn226hLc0j3RhtxMFKXDxI21EauFfBFQRJ55lIMajie4Z7tqvoLih_29QX7PsQ2mBQR_dyKfsfU-e5FQMdLAF3L2PYpEuk9QSBEbyNn6hZrL1oXEEI-gr7jK4rp7d3T0dMndu6OQmS9P5QX9osBM6POC3eiMwSw0zl4OoubROJebLBjdtXglb6nYL3mIyTDZ7VTMIflTtN50ze4T4xE95Ax4gj_KkUxaV3WuN82KbZLZw',
    type: 'PI',
    tenantId: '30258aed-46bb-4ec5-909e-ecea2431772b',
    tenantKey: '6551b60c-b521-4949-be3a-1be09d8ede6f',
    userId: 'ffffffff5c93183752faff016a1cb4c2',
    userRole: 'STUDENT',
    versionUrn: 'versionUrn'
  }
};

export default appConfig;
