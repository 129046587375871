/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Sample Icon component
 *
 * @file CalendarSample.js
 * @author Balaji Ganesh
 * @since 2.0.0
 */

import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';


class CalendarSample extends PureComponent {
  constructor() {
    super();
    this.state = {
      showDialog: false
    };
  }

  iconClick = () => {
    this.setState({
      showDialog: true
    });
  }

  handleClose = () => {
    this.setState({
      showDialog: false
    });
  }

  renderDialog = () => {
    const { showDialog } = this.state;

    return (
      <Dialog
        open={showDialog}
        onClose={this.handleClose}
      >
        <DialogTitle>
          Scheduled Reading
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eu nisl urna. Cras quis tristique risus,
            eu vestibulum diam. Etiam varius ac odio sit amet cursus. Praesent vitae efficitur mauris, eget ornare
            nisl. Sed laoreet in lectus et venenatis. Integer sodales augue sit amet purus dapibus fermentum. Vivamus
            et felis porttitor, sodales lorem aliquet, sollicitudin lacus. Maecenas at neque facilisis lectus
            cursus congue sit amet quis sem. Duis gravida ut urna sed convallis. Quisque ornare justo ante,
            eget aliquam dui elementum at. Donec vitae sem ligula. Ut at mollis risus. Nulla pharetra mauris
            sed enim rhoncus laoreet.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    return (
      <>
        <IconButton onClick={this.iconClick}>
          <DateRangeIcon />
        </IconButton>
        {this.renderDialog()}
      </>
    );
  }
}

export default CalendarSample;
