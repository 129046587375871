/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Constants used in this component library
 *
 * @file constants.jsx
 * @since 2.0.0
 */

export const BOOK_DATA_ARRAY = 'booksData';
export const BOOK_FORMAT_CITE = 'CITE';
export const BOOK_FORMAT_EPUB = 'EPUB';
export const BOOK_FORMAT_PDF = 'PDF';
export const BOOK_FORMAT_PXE = 'PXE';
export const BOOK_FORMAT_SPDF = 'SPDF';
